import { createApp } from 'vue'
import '@unocss/reset/tailwind.css'
import 'virtual:uno.css'
import '@/assets/main.css'
import App from './App.vue'
import { router } from '@/plugins/router.ts'

createApp(App)
  .use(router)
  .mount('#app')
